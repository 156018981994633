@font-face {
  font-family: "Inter";
  src: url("./fonts/Inter.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "neodgm";
  src: url("./fonts/neodgm.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Cafe24SSurround";
  src: url("./fonts/Cafe24Ssurround.ttf") format("truetype");
  font-display: swap;
}
@font-face {
  font-family: "Cafe24SSurroundAir";
  src: url("./fonts/Cafe24SsurroundAir.ttf") format("truetype");
  font-display: swap;
}


.App {
  margin-bottom: 50px;
  font-family: "Pretendard";
  width: 100vw;
}

img {
  max-width: 100%;
}

video {
  width: 100%;
}

iframe {
  width: 100%;
}

figure {
  margin: 0px;
}

.toastui-editor-popup {
  margin-left: 0px;
}

.toastui-editor-contents h1 {
  border-bottom: 0px;
}

.toastui-editor-contents h2 {
  border-bottom: 0px;
}
.toastui-editor-contents p{
  font-size: 15px;
}